// src/components/Merchandise.js
import React from 'react';
import './merchandise.css';
import bandImage from './bandImage.jpg';
import cd from './cd.jpeg';
import vinyl from './vinyl.jpeg';
import comingSoon from './comingsoon.jpg';   // Import the image


const merchandiseItems = [
  {
    name: "Black Dog Moon CD/ Vinyl & Digital Download",
    image: cd,
    bandcampLink: "https://conalmontgomery.bandcamp.com/album/black-dog-moon"
  },
  {
    name: "T-Shirt",
    image: comingSoon,
    bandcampLink: "https://bandcamp.com/your-tshirt-link"
  },
  {
    name: "Poster",
    image: comingSoon,
    bandcampLink: "https://bandcamp.com/your-poster-link"
  }
  // Add more items as needed
];

const Merchandise = () => {
  return (
    <div className="merchandise-container">
      <h1>Merchandise</h1>
      <div className="merchandise-grid">
        {merchandiseItems.map((item, index) => (
          <div key={index} className="merchandise-item">
            <a href={item.bandcampLink} target="_blank" rel="noopener noreferrer">
              <img src={item.image} alt={item.name} />
              <p>{item.name}</p>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Merchandise;